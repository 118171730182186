import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import Layout from '../layout/layout.js';
import shareiconblack from '../assets/images/ic-share-black.svg';
import shareicon from '../assets/images/ic-share-arrow.svg';
import noSectionMedImage from '../assets/images/no-image-medium.png';
// import noSectionBigImage from '../assets/images/no-image-big.png';
import ScrollAnimation from 'react-animate-on-scroll';
import Pagination from 'rc-pagination';
// import CF from '../components/commonfunctions';
import { navigate } from "@reach/router";
import { graphql } from 'gatsby';
// import ReadMoreAndLess from 'react-read-more-less';
import moment from "moment";
import Search from '../components/search';
import ScaleLoader from "react-spinners/ScaleLoader";
// import highnotessliderimg from '../assets/images/highnotes-slider-img.png';
import { share_url } from '../config/configs';
import { isLoggedIn } from "../services/auth";
import { DIZZEE_API, DIZZEE_LOGIN, S3_URL } from "gatsby-env-variables";
import { window } from 'browser-monads';
import axios from "axios";

import featuresIcon from '../assets/images/ic-features.svg';
import announcementsIcon from '../assets/images/ic-whats-new.svg';
import tutorialsIcon from '../assets/images/ic-tutorials.svg';
import caseStudiesIcon from '../assets/images/ic-resources.svg';
import showAllIcon from '../assets/images/ic-show-all.svg';
import searchIcon from '../assets/images/ic-search.svg';
import subscribeIcon from '../assets/images/ic-subscribe.svg';

const navMenuArray = [featuresIcon,announcementsIcon,tutorialsIcon,caseStudiesIcon,showAllIcon,searchIcon,subscribeIcon];
// const dizzeeLogin = `${DIZZEE_LOGIN}&client=umx&redirect=${encodeURIComponent(window.location.href)}`;

export const query = graphql`
{
  WordPress {
    menu(id: "dGVybTozMw==") {
      name
      menuItems {
        nodes {
          label
          cssClasses
          menu_data {
            externalLink
            sectionId
            svg {
              sourceUrl
            }
          }
        }
      }
    },
    themeGeneralSettings {
      theme_settings {
        highNotesGoodToKnow {
          ... on WordPress_Highnote {
            title
            date
            content
            uri
            slug
            high_notes {
              highNotesIcon{
                sourceUrl
              }
              sliderImages {
                image {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
  }
  `
let pageSize = 9;
const Highnotes = ({ data }) => {

  const [list, listData] = useState([]);
  const [page, newPage] = useState(1);
  const [total, newTotal] = useState();
  const [isOpen, setIsopen] = useState(false);
  const [scrollOffset, setOffset] = useState(0);
  const [viewAll, setViewAll] = useState(false);
  const [pageListAllData, setPageListAllData] = useState([]);
  const [isSticky, setSticky] = useState(false);
  const [banner, setBanner] = useState([]);
  const [user, setUser] = useState();
  const [disableBlog, setDisableBlog] = useState();
  const settings = {
    dots: true,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
  };

  const settings1 = {
    dots: false,
    arrows: true,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
  };


  const settings2 = {
    dots: false,
    arrows: true,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '560px',
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          centerPadding: '500px',
        }
      },
      {
        breakpoint: 1600,
        settings: {
          centerPadding: '400px',
        }
      },
      {
        breakpoint: 1199,
        settings: {
          centerPadding: '300px',
        }
      },
      {
        breakpoint: 991,
        settings: {
          centerPadding: '200px',
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '0px',
        }
      },
    ]
  };


  const handleScroll = (e) => {
    var headerH = document.querySelector('header') ? document.querySelector('header').offsetHeight : "";
    setSticky(window.scrollY > headerH ? true : false);
  };
  const handleCategory = (e, label, id) => {
    if (label == "Search") {
      setIsopen(!isOpen)
    }
    else if (label == "Resources") {
      navigate('/resources')
    }
    else if (label == 'Subscribe') {
      e.preventDefault();
      e.stopPropagation();
      const scrollSec = document.querySelector(e.target.hash);
      const headerOffset = document.getElementsByClassName("category-menu-outer") && document.getElementsByClassName("category-menu-outer")[0].clientHeight;
      const offsetH = (scrollSec.offsetTop - (!isSticky ? 108 + headerOffset : 108));
      setOffset(offsetH)
      window.scrollTo({ behavior: "smooth", top: offsetH });
    }
    else if (label === 'Show All') navigate(`/highnotes-category?/showall`)
    else if (label === "Category 1") navigate(`/highnotes-category?/${id}/category1`)
    else if (label === "Category 2") navigate(`/highnotes-category?/${id}/category2`)
    else if (label === "Category 3") navigate(`/highnotes-category?/${id}/category3`)
    else if (label === "Category 4") navigate(`/highnotes-category?/${id}/category4`)
  }

  useEffect(() => {
    getListDataApi();
    // highNotes();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!user && isLoggedIn()) {
      axios.get(`${DIZZEE_API}/action/user/data?apps=all`, {
        withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            application: "swiftTrends"
          },
        }).then((resp) => {
          if (resp.data.info === "SUCCESSFULLACTION") {
            setUser(resp.data.userInfo);
          }
        }).catch(error => {
          if (error.response) {
            console.log('error', error.response.data);
          }
        });
    }
  }, []);

  const getListDataApi = async () => {

    let data = await fetch(S3_URL +`/high_notes.json`)
    .then(response=>{
      return response.json()
    })
    let dataArray = [...data]
    setPageListAllData(data);
    let slicedArray = dataArray.slice(0, pageSize);
    listData(slicedArray)
    newPage(1);
    newTotal(Number(data.length))
  }

  const pagination = async (e) => {

    let dataArray = [...pageListAllData]
    let slicedArray = dataArray.slice(pageSize*(e-1), pageSize*e);
    listData(slicedArray)
    newPage(e ? e : 1);
  }



  let menuArr = data.WordPress.menu && data.WordPress.menu.menuItems && data.WordPress.menu.menuItems.nodes;
  let goodToKnow = data.WordPress && data.WordPress.themeGeneralSettings && data.WordPress.themeGeneralSettings.theme_settings && data.WordPress.themeGeneralSettings.theme_settings.highNotesGoodToKnow;
  let icon = banner && banner.acf && banner.acf.high_notes_icon && banner.acf.high_notes_icon.url ? banner.acf.high_notes_icon.url : "";
  let content = banner && banner.content && banner.content.rendered && (banner.content.rendered).substring(3, (banner.content.rendered).length - 5);
  let name = banner && banner.title && banner.title.rendered ? banner.title.rendered : "";
  let slug = banner && banner.slug ? banner.slug : "";
  let date = banner && banner.date ? moment(banner.date).format('ll') : "";
if (isLoggedIn(user) && !disableBlog) {
  return (
    <React.Fragment>
      {list && list.length && Array.isArray(list) ?
        <Layout uma="inner-page" headerColor="header-highnotes" title={"News"} seoTitle={'UMX News'}>

          {/* Banner Section Start */}
          <section className="common-banner highnotes-banner">
            <div className={`category-menu-outer highnotes-category-menu-outer ${isSticky ? 'header-category-fixed' : ''}`}>
              <div className="container">
                {/********************* Category Menu Start *********************/}
                <nav className="category-menu">
                  <ul>
                    {menuArr && menuArr.length ? menuArr.map((menu, key) => {
                      if (menu && menu.label !== "Search") {
                        return (<li className="seven-category" key={key} onClick={(e) => handleCategory(e, menu.label, menu.menu_data.sectionId)}>
                          <a id="responsive-menu-trigger" href={menu && menu.label === 'Subscribe' ? '#conatctus' : menu.label === "Search" && "#"}><span className="category-img">
                            <img src={navMenuArray[key]} alt={menu.label} />
                          </span>
                            <span className="category-name">{menu.label}</span>
                          </a>
                        </li>
                        )
                      }
                    }) : null}
                  </ul>
                </nav>
                {/********************* Category Menu End *********************/}
              </div>
            </div>
            <div className="container">
              <div className="common-banner-content">
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <h1 className="color-white">UMX News</h1>
                </ScrollAnimation>
              </div>
            </div>
            <Search page="highNotes" />
            <div className="highnotes-slider-wrap">
              <div className="container">
                {/* Slider Section Start */}
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                  <div className="highnotes-main-slider">
                    <Slider {...settings}>
                      {
                        goodToKnow && goodToKnow.length && goodToKnow.map((each, key) => {
                          let content = each && each.content ? each.content : "";
                          let des = content.substring(3, (each.content).length - 5);
                          let title = each && each.title ? each.title : "";
                          let date = each && each.date && moment(each.date).format('ll');
                          let image = each && each.high_notes && each.high_notes.sliderImages && each.high_notes.sliderImages[0] && each.high_notes.sliderImages[0].image ? each.high_notes.sliderImages[0].image.sourceUrl : noSectionMedImage;
                          let icon = each && each.high_notes && each.high_notes.highNotesIcon && each.high_notes.highNotesIcon.sourceUrl ? each.high_notes.highNotesIcon.sourceUrl : "";
                          let slug = each && each.slug ? each.slug : '';
                          return (
                            <div>
                              <div className="highnotes-slider-content" key={key}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="resource-box">
                                      <div className="category-show">
                                        <img src={icon} />
                                      </div>
                                      <h6>{title}</h6>
                                      <span className="date">{date}</span>
                                      <div className="resource-desc">
                                        <p>{des}</p>
                                        <div className={des && des.length > 60 ? "more-share-sec more-share-sec-mt" : "more-share-sec"}>
                                          {
                                            des.length < 50 ? null : <a href="" onClick={() => navigate(`/blog-details/?slug=${slug}`)} className="continue-read-btn">CONTINUE READING</a>
                                          }
                                          <a href={"mailto:?Subject=X music&Body=" + share_url + `/blog-details?slug=${slug}`} className="share">
                                            <span className="share">SHARE</span>
                                            <span className="share-icon"><img src={shareicon} alt="Share" /></span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="highnotes-slider-img">
                                      <img src={image} alt={title} />
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          )
                        })}

                    </Slider>
                  </div>
                  {/* Slider Section End */}
                </ScrollAnimation>
              </div>
            </div>

          </section>

          <section className="highnotes-listing-main">
            <div className="container">
              {list && list.length && list.map((each, id) => {
                let images = each && each.acf && each.acf.slider_images && each.acf.slider_images;
                let icon = each && each.acf && each.acf.high_notes_icon && each.acf.high_notes_icon.url;
                let content = each && each.content && each.content.rendered && (each.content.rendered).substring(3, (each.content.rendered).length - 5);
                let name = each && each.title && each.title.rendered ? each.title.rendered : "";
                let slug = each && each.slug ? each.slug : "";
                let date = each && each.date ? moment(each.date).format('ll') : "";
                if (id < 4 || viewAll) {
                  return (<React.Fragment key={id}>
                    <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                      <div className="highnotes-listing-block">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="highnotes-listing-img">
                              <Slider {...settings1}>
                                {images && images.length ? images.map((img, key) => {
                                  let photos = img && img.image && img.image.url ? img.image.url : noSectionMedImage;
                                  return (<div className="highnotes-listing-inner-img" key={key}>
                                    <img src={photos} alt={name} />
                                  </div>)
                                }) : <div className="highnotes-listing-inner-img">
                                    <img src={noSectionMedImage} alt={name} />
                                  </div>}
                              </Slider>
                            </div>
                          </div>
                          <div className="col-md-6 offset-md-1">
                            <div className="highnotes-listing-content" onClick={() => navigate(`/blog-details/?slug=${slug}`)}>
                              <div className="resource-box">
                                <div className="category-show">
                                  {/* <span className="category bg-yellow-cat">Documentation</span> */}
                                  <img src={icon} alt={name} />
                                </div>
                                <h6>{name}</h6>
                                <span className="date">{date}</span>
                                <div className="resource-desc">
                                  <p>{content}</p>
                                  <div className={content && content.length < 300 ? "more-share-sec " : "more-share-sec more-share-sec-mt"}>
                                    {
                                      content.length < 300 ? null : <a href="" onClick={() => navigate(`/blog-details/?slug=${slug}`)} className="continue-read-btn">CONTINUE READING</a>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                  </React.Fragment>)
                }
              })}

              {list && list.length >= 4 && !viewAll ? <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className="view-all-sec">
                  <a href="javascript:;" className="view-all-btn" onClick={() => setViewAll(true)}>{viewAll === false ? 'VIEW ALL' : ''}</a>
                </div>
              </ScrollAnimation> : ""}
              {viewAll ? <div className="row">
              <div className="col-md-12">
                <Pagination showLessItems pageSize={pageSize} current={page} total={total} onChange={(e) => pagination(e)} locale={true} />
              </div>
            </div> : ""}
            </div>
          </section>


          {/* Good to Know Section Start */}
          <section className="good-to-know-sec">
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <h3 className="text-center color-black">Recommended</h3>
            </ScrollAnimation>
            <div className="recommend-slider-wrap good-to-know-slider-wrap">
              <Slider {...settings2}>
                {goodToKnow && goodToKnow.length && goodToKnow.map((each, key) => {

                  let content = each && each.content ? each.content : "";
                  let des = content.substring(3, (each.content).length - 5);
                  let title = each && each.title ? each.title : "";
                  let date = each && each.date && moment(each.date).format('ll');
                  let image = each && each.high_notes && each.high_notes.sliderImages && each.high_notes.sliderImages[0] && each.high_notes.sliderImages[0].image ? each.high_notes.sliderImages[0].image.sourceUrl : noSectionMedImage;
                  let icon = each && each.high_notes && each.high_notes.highNotesIcon && each.high_notes.highNotesIcon.sourceUrl ? each.high_notes.highNotesIcon.sourceUrl : "";
                  let slug = each && each.slug ? each.slug : '';
                  return (<div className="resource-box-slider" key={key}>
                    <div className="resource-box-img">
                      <img src={image} alt={title} />
                    </div>
                    <div className="resource-box">
                      {icon ? <div className="category-show">
                        <img src={icon} alt={title} />
                        {/* <span className="category bg-yellow-cat">Documentation</span> */}
                      </div> : ""}
                      <h6>{title}</h6>
                      <span className="date">{date}</span>
                      <div className="resource-desc">
                        <p>{des}</p>
                        <div className={des && des.length < 50 ? "more-share-sec" : "more-share-sec more-share-sec-mt"}>
                          {
                            des.length < 50 ? null : <a href="" onClick={() => navigate(`/blog-details/?slug=${slug}`)} className="continue-read-btn">CONTINUE READING</a>
                          }
                          <a href={"mailto:?Subject=X music&Body=" + share_url + `/blog-details?slug=${slug}`} className="share">
                            <span className="share">SHARE</span>
                            <span className="share-icon"><img src={shareiconblack} alt="Share" /></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>)
                })}
              </Slider>
            </div>
          </section>
          {/* Good to Know Section End */}
        </Layout> : <div className="product-loader"><ScaleLoader color={'#141618'} size={150} /></div>}
    </React.Fragment>
  );
} else if (!isLoggedIn() || disableBlog) {
  navigate('/');
  return null;
}
};
export default Highnotes;
